import { Credentials, TrustedTime } from "@archipad-js/archipad/archiweb-services-client";
import { LegacyAccountService } from "./legacy-account.service";
import { DIID } from "@archipad-js/dependency-injection";

export interface AccountInfo {
    id: number;
    contact: Contact;
    company: Company;
    credential: Credentials;
    dependencies: Array<Dependency>;
    lang: string;
    lastLoginDate: string;
    firstName: string;
    lastName: string;
    marketing: {
        id: number;
        knowsFrom: string | null;
    };
    creationDate: TrustedTime;
    isConfirmed: boolean;
    isActive: boolean;
    displayProductBanner: boolean;
    acceptCommercialCommunication: boolean;
    root?:boolean;
}

export interface Company {
    id: number;
    name: string;
    contact: Contact;
    vat: string;
}

export interface Contact {
    id: number;
    country: string;
    state: string;
    streets: string;
    city: string;
    zip: string;
    email: string;
    landLine: string;
    mobile: string;
}

export interface Dependency {
    bundleId: string;
}

export interface FormCredentials {
    login: string;
    password: string;
}

export type HashedCredentials = {
    login: string;
    hashedPassword: string;
}

export type StoredCredentials = HashedCredentials;

export type UpdateCredentialsPayload = {
    id: number;
    login: string;
    currentPassword: string;
    password: string;
}

export interface AccountPlan {
    acquirableProductId:string;
    productId:string;
    startDate:number;
    endDate:number | null;
    billingDate: number | null;
    recurring:boolean;
    canAutoRenew:boolean;
    couldHaveFreeTrial?:boolean;
    trial?:boolean
    displayName?: string;
    previous?:PreviousPlan;
    additionalProducts: {
        [key:string]: {
            productId:string;
            startDate:number;
            endDate:number | null;
            acquirableProductId:string;
            displayName?: string;
            longDisplayName?: string;
        }
    }
}

export type PreviousPlan = Pick<AccountPlan, "acquirableProductId" | "productId" | "startDate" | "endDate" | "trial">;

export type Plan = PreviousPlan | AccountPlan;

export interface LegacyUserInformationInterface {
    adminLogin: string;
    credentialLogin: string;
    id: number;
    isRestricted: boolean;
    isRoot: boolean;
    login: string;
}

export interface MarketingInfo {
    id: number,
    knowsFrom: string | null,
}

export type CreateAccountPayload = {
    type: string,
    firstName?: string,
    lastName?: string,
    lang: string,
    credential: {
       type: string,
       login: string,
       password?: string,
    },
    contact?: Pick<Contact, 'email'>,
    company?: {
        name: Company['name'],
        contact: Pick<Contact, 'landLine'>,
    },
    marketing?: {
        knowsFrom: string,
    },
    activateFreeTrial?: boolean,
}

export type ContactMePayload = {
    templateName: string,
    accountInfos: {
        companyName : string,
        displayName : string,
        email : string,
        phone : string,
    },
    concern: string,
}

export type UpdateAccountPayload = {
    id: number,
    firstName?: string,
    lastName?: string,
    lang?: string,
    company: CompanyUpdatePayload,
}

export type CompanyUpdatePayload = {
    name: Company['name'],
    vat?: Company['vat'],
    contact: {
        country?: Contact['country'],
        state?: Contact['state'],
        streets?: Contact['streets'],
        city?: Contact['city'],
        zip?: Contact['zip'],
        email?: Contact['email'];
        landLine?: Contact['landLine'];
        mobile?: Contact['mobile'];
    },
}

export const Legacy_Account_Service_DIID = DIID<LegacyAccountService>("LegacyAccountService");