import Env from "@env";

export function isDebugEnv(): boolean {
    let isDebug = false;

    // localhost names white list
    const localhostNames = [
        'localhost',
        '127.0.0.1',
        'archipad.local',
    ];

    if(typeof window == "undefined") {
        return isDebug;
    }

    // check if the current hostname match one of the white list
    for (const localhostName of localhostNames) {
        if (window.location.host.startsWith(localhostName)) {
            isDebug = true;
            break;
        }
    }

    let query = window.location.search;
    if (query) {
        query = query.substring(1);
        const args = query.split('&');
        for (let i = 0; i < args.length; i++) {
            if (!args[i].indexOf('debug=')){
                isDebug = args[i].substring(6) == "true";
            }
        }
    }
    
    return isDebug;
}

/**
* [PLUS-158] Remove this function 
*/
export function isFeatureFlagsPlusAuthenticationServerEnable(): boolean {
    let isFeatureFlagsPlusAuthenticationServerEnable = Env["featureFlags"]?.['plusAuthenticationServer'];

    if( 
        typeof window == "undefined" ||
        typeof sessionStorage == "undefined"
    ) {
        return isFeatureFlagsPlusAuthenticationServerEnable;
    }

    const currentSessionStorageValue = sessionStorage.getItem('auth');

    const params = new URLSearchParams(window.location.search);
    if (params) {
        const auth = params.get("auth");
        if(auth) {
            isFeatureFlagsPlusAuthenticationServerEnable = auth !== "legacy" // default to true if any other value than "legacy"
            sessionStorage.setItem("auth", auth);
            if(auth !== currentSessionStorageValue) {
                location.reload();
            }
        }
    }

    if(currentSessionStorageValue) {
        return currentSessionStorageValue !== "legacy";
    }
    
    return isFeatureFlagsPlusAuthenticationServerEnable;
}
